<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <template v-if="success">
                                    <div class="text-success">
                                        <font-awesome-icon style="font-size: 40px"
                                                           class="text-success mr-2"
                                                           icon="check-circle"/>
                                        {{ $t('common.toasts.mail_track_success') }}
                                    </div>
                                </template>
                                <template v-if="success==false">
                                    <div class="text-danger">
                                        <font-awesome-icon style="font-size: 40px" class="text-danger mr-2"
                                                           icon="times-circle"/>
                                        {{ $t('common.toasts.mail_track_failed') }}
                                    </div>
                                </template>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>

export default {
    name: 'Unsubscribe',
    data() {
        return {
            success: null,
        }
    },
    mounted() {
        let sendData = {
            'hash': this.$route.params.hash,
        }
        window.axios.post(window.apiUrl + '/track-mail', sendData, {'skip_loading': true})
            .then(response => {
                if (response.data.status == true) {
                    this.success = true
                } else {
                    this.success = false
                }
            })
            .catch(() => {
                this.success = false
            })
            .finally(()=>{
                this.$root.$children[0].loading = false
            })
    },
    methods: {
        // unsubscribe() {
        //
        // },
    }
}
</script>

<style lang="scss">
.c-app {
    background-color: transparent;
}
</style>